
.accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: rgba(163, 105, 50,.90);
    box-shadow: inset 0 -1px 0 rgba(212, 120, 0, 0.13);
    opacity: "60%";
}

.accordion-button {
    color: #ffffff;
    background-color: rgba(163, 105, 50,.90);
    box-shadow: inset 0 -1px 0 rgba(212, 120, 0, 0.13);
    opacity: "60%";
}

.accordion-button:focus {
    z-index: 3;
    border-color: #ffffff;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.13);
    opacity: "60%";
}